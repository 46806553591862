import { Injectable } from '@angular/core';
import { UserSegment } from '@app/core/models/payment/user-segment.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSegmentService {
  constructor() {}

  getSegment(): UserSegment {
    return JSON.parse(sessionStorage.getItem('user') as string)?.segment;
  }

  getRpSmsBanner(): string {
    return this.getSegment().custrecord_segment_banner_rpsms;
  }

  getMaxDirectDebitAmount(): string {
    return this.getSegment().custrecord_maximum_direct_debit_month;
  }

  getProviderBanner(): string {
    return this.getSegment().custrecord_segment_banner_provider;
  }

  getCreditCardSurchage(): number {
    return this.getSegment().creditCardSurchage;
  }

  getBankAccountFee(): string {
    return this.getSegment().custrecord_bank_account_fee;
  }

  getMaxTermMonthsMoreThan2k(): string {
    return this.getSegment().custrecord_maximum_term;
  }

  getMaxTermMonthsLessThanOr2k(): string {
    return this.getSegment().custrecord_maximum_term_2000;
  }

  getMinPaymentPlanAmount(): string {
    return this.getSegment().custrecord_minimum_amount_plan;
  }

  getMaxAmendmentIncrease(): string {
    return this.getSegment().custrecord_max_amendment_inc_plan;
  }

  getMaxPaymentPlanAmount(): string {
    return this.getSegment().custrecord_maximum_amount_plan;
  }

  getMaxStartDateInDays(): string {
    return this.getSegment().custrecord_max_startdate_in_days;
  }

  getMaxTotalTreatmentCost(): string {
    return this.getSegment().custrecord_max_ttc;
  }

  getMaxTotalTreatmentCostDenOrtho(): string {
    return this.getSegment().custrecord_max_tc_den_ortho;
  }

  getMinDepositDenOrtho(): string {
    return this.getSegment().custrecord_min_deposit_amt_den_ortho;
  }

  getMinDepositRatio(): string {
    return this.getSegment().custrecord_min_deposit_ratio;
  }

  getBrandTncTextRp(): string {
    return this.getSegment().custrecord_brand_tnc_text_rp;
  }

  getBrandProviderCompliance(): string {
    return this.getSegment().custrecord_brand_provider_compliance;
  }

  getMaxTotalTreatmentCost15k(): string {
    return this.getSegment().custrecord_max_ttc_15k;
  }

  getMaxPaymentPlanAmount15k(): string {
    return this.getSegment().custrecord_maximum_amount_plan_15k;
  }

  getMaxDirectDebitAmount15k(): string {
    return this.getSegment().custrecord_maximum_direct_debit_month15k;
  }
}
