import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsbValidationResponse } from '@app/core/models/payment/bsb-validation.interface';
import { Progress } from '@app/core/models/payment/progress.interface';
import { CommentPropertyName } from '@app/payments/modals/comment-modal/comment-modal.component';
import {
  PostConfirmOtpRequest,
  PostConfirmOtpResponse,
  PostSendOtpRequest
} from '@app/sms-journey/contact-verification/contact-verification.interface';
import { PutPendingPlanResponse } from '@app/sms-journey/interface/rp-sms-journey.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  ErrorResponse,
  GenericListResponse,
  GenericModelResponse,
  KycPayload,
  OtpResponse,
  PaymentMissedItemResponse,
  PaymentPlanItemResponse,
  SmsJourneyPlan,
  UnsubmittedPlan
} from '../../models';
import { ApiUrlService } from '../../services/api-url.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  getPaymentPlanUnSubmitted(): Observable<GenericListResponse<UnsubmittedPlan, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<UnsubmittedPlan, ErrorResponse>>(`${this.apiUrlService.savedPlanUrl}`);
  }

  getUnsubmittedPlanById$(id: string): Observable<GenericModelResponse<SmsJourneyPlan, ErrorResponse>> {
    return this.httpClient.get<GenericModelResponse<SmsJourneyPlan, ErrorResponse>>(`${this.apiUrlService.savedPlanUrl}/${id}`);
  }

  getApprovalQueue$(): Observable<GenericListResponse<PaymentPlanItemResponse, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<PaymentPlanItemResponse, ErrorResponse>>(
      `${this.apiUrlService.paymentPlanSummaryUrl}?moduleType=getPendingApprovalDdrList`
    );
  }

  downloadAgreement(key: string): Observable<GenericModelResponse<string, HttpErrorResponse>> {
    return this.httpClient
      .get<GenericModelResponse<string, HttpErrorResponse>>(this.apiUrlService.paymentPlanSummaryUrl + `/${key}/download-agreement`)
      .pipe(filter((response) => !!response?.data));
  }

  sendAppLink(plan: any): Observable<any> {
    return this.httpClient.post(this.apiUrlService.sendAppLinkUrl, plan);
  }

  postSmsJourneyPreviewProposalPdf(payload: any, ddrDraftId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlService.postSmsJourneyPreviewProposalPdf}/${ddrDraftId}`, payload).pipe(
      map((response) => {
        return response.base64PdfString;
      })
    );
  }

  postSubmitPendingPlan(plan: any, urlParams: any): Observable<any> {
    return this.httpClient.post(this.apiUrlService.savedPlanNoLoginUrl + '/' + urlParams, plan);
  }

  postCreateEditPendingPlan(body: PostPendingPlanRequest): Observable<PostPendingPlanResponse> {
    return this.httpClient.post<{ data: any; error: any[]; isError: string }>(`${this.apiUrlService.savedPlanUrl}`, body).pipe(
      map((response) => {
        return response?.data;
      })
    );
  }

  getSendSms(ddr: string, ddrSource: string): Observable<GetSendSmsResponse> {
    return this.httpClient
      .get<{ data: GetSendSmsResponse; error: any[]; isError: string }>(
        `${this.apiUrlService.smsSavedPlanUrl}/${ddr}?company_brand=${environment.company_brand}&ddr_source=${ddrSource}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  updatePendingPlan(plan: SmsJourneyPlan, ddrDraftId: string): Observable<PutPendingPlanResponse> {
    return this.httpClient.put<PutPendingPlanResponse>(`${this.apiUrlService.savedPlanNoLoginUrl}/${ddrDraftId}`, plan);
  }

  getDepositInvoiceFile(ddrId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrlService.getDepositInvoice}`, { ddrID: ddrId });
  }

  sendOTP(ddrDraftId: string, payload: PostSendOtpRequest): Observable<OtpResponse[]> {
    // declare api endpoint with param link
    const apiLink = `${this.apiUrlService.sendOTPUrl}/${ddrDraftId}`;
    return this.httpClient.post(apiLink, payload).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  resendOTP(ddrDraftId: string, payload: PostSendOtpRequest): Observable<OtpResponse[]> {
    // declare api endpoint with param link
    const apiLink = `${this.apiUrlService.resendOTPUrl}/${ddrDraftId}`;
    return this.httpClient.post(apiLink, payload).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  confirmOtp(ddrDraftId: string, payload: PostConfirmOtpRequest): Observable<PostConfirmOtpResponse> {
    const apiLink = `${this.apiUrlService.confirmOTPUrl}/${ddrDraftId}`;
    return this.httpClient.post<{ data: PostConfirmOtpResponse; error: any[]; isError: string }>(apiLink, payload).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  verifyKyc(ddrDraftId: string, payload: KycPayload): Observable<PostVerifyKycResponse> {
    const apiLink = `${this.apiUrlService.verifyKYC}/${ddrDraftId}`;

    return this.httpClient.post<{ data: PostVerifyKycResponse; error: any[]; isError: string }>(apiLink, payload).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  checkBsb(ddrDraftId: string, bsbNumber: string): Observable<BsbValidationResponse[]> {
    const apiLink = `${this.apiUrlService.bsbCheckNoLoginUrl}/${ddrDraftId}`;

    return this.httpClient.post<{ data: BsbValidationResponse[]; error: any[]; isError: string }>(apiLink, { bsbNumber }).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  processDepositPayment(ddrDraftId: string, payload: PostDepositPaymentRequest): Observable<PostProcessCcPaymentResponse | null> {
    return this.httpClient
      .post<{ data: PostProcessCcPaymentResponse; error: any[]; isError: string }>(
        `${this.apiUrlService.postProcessPayment}/${ddrDraftId}`,
        payload
      )
      .pipe(
        map((response) => {
          if (!response?.data?.ccapproved) {
            return null;
          }
          return response?.data;
        })
      );
  }

  getPlanProgressStep(ddrDraftId: string): Observable<Progress[]> {
    return this.httpClient
      .get<{ data: Progress[]; error: any[]; isError: string }>(`${this.apiUrlService.getPlanProgressStep}/${ddrDraftId}`)
      .pipe(
        map((response) => {
          return response?.data;
        })
      );
  }

  processPayInFullPayment(ddrDraftId: string, payload: PostPayInFullPaymentRequest): Observable<PostProcessCcPaymentResponse | null> {
    return this.httpClient
      .post<{ data: PostProcessCcPaymentResponse; error: any[]; isError: string }>(
        `${this.apiUrlService.postProcessPayment}/${ddrDraftId}`,
        payload
      )
      .pipe(
        map((response) => {
          if (!response?.data?.ccapproved) {
            return null;
          }
          return response?.data;
        })
      );
  }

  getPaymentPlanMissed$(): Observable<{
    isError: string;
    readonly data: PaymentMissedItemResponse[];
    readonly errors: ErrorResponse[];
  }> {
    return this.httpClient.get<{
      isError: string;
      readonly data: PaymentMissedItemResponse[];
      readonly errors: ErrorResponse[];
    }>(`${this.apiUrlService.missedPaymentsUrl}`);
  }

  saveComment$(id: string, body: any, module: CommentPropertyName) {
    let url = '';
    if (module === CommentPropertyName.DDR_PLANS) {
      url = this.apiUrlService.updatePaymentPlanUrl;
    } else {
      url = this.apiUrlService.updateSavedPlanUrl;
    }

    return this.httpClient.put<any>(`${url}/${id}`, body).pipe(
      map((response) => {
        return response?.data;
      })
    );
  }
}

export interface PostPendingPlanRequest {
  confim_details_cbx: boolean | null;
  custrecord_ddrip_proactive: boolean;
  custrecord_mfa_ddr_copy_rp_fields: boolean;
  custrecord_mfa_ddr_patient_dob: string;
  custrecord_mfa_ddr_single_payments: number;
  custrecord_mfa_ddr_term_length: string;
  custrecord_override_term_length: boolean;
  custrecord_pa_overwrite_payment_amount: number;
  ddr_source: string;
  deposit: number;
  doctor_id: string;
  no_of_payments: number;
  ortho_items: string[];
  patient_firstname: string;
  patient_lastname: string;
  patient_title: string;
  payment_freq: string;
  payment_plan_total: number;
  plan_type: string;
  practice_id: string;
  practice_patient_id_no: string;
  quote: PaymentPlanQuote[];
  reset_plan_progress: boolean;
  rp_dob: string;
  rp_firstname: string;
  rp_lastname: string;
  rp_mobile_num: string;
  rp_mobile_num2: MobileNumber;
  rp_title: string;
  rp_email: string;
  start_date: string;
  template: string;
  total_plan_value: string;
  treatment_type: string;
  custrecord_ddrip_old_saved_plan_id: string;
  prev_ddr: string;
}

interface MobileNumber {
  number: string;
  internationalNumber: string;
  nationalNumber: string;
  e164Number: string;
  countryCode: string;
  dialCode: string;
}

interface PaymentPlanQuote {
  term_weeks?: string;
  term_months: number;
  weekly: number;
  fortnightly: number;
  monthly: number;
}

export interface PostPendingPlanResponse {
  ddr: number;
  ddr_plan_type_final: string;
}

export interface GetSendSmsResponse {
  0: GetSendSmsDataResponse;
  key: string;
  resumeUrl: string;
  signedUrl: string;
}

interface GetSendSmsDataResponse {
  field: string;
  message: string;
}

export interface PostVerifyKycResponse {
  custrecord_ddrip_greenid_overall_status: string;
  custrecord_ddrip_greenid_verification_id: string;
  message: string;
  reset_rp_name: boolean;
}

export interface PostDepositPaymentRequest {
  ccNumber: string;
  ccExpireDate: string;
  ccName: string;
  ccPaymentMethod: string;
  depositAmount: string;
  planType: string;
}

export interface PostPayInFullPaymentRequest {
  ccNumber: string;
  ccExpireDate: string;
  ccName: string;
  ccPaymentMethod: string;
  pifAmount: string;
  planType: string;
}

export interface PostProcessCcPaymentResponse {
  amount: string;
  ccnumber: string;
  date: string;
  tranid: string;
  salesorderid: number;
  ccapproved: boolean;
  message: string; // For failed payment
}
