<div *ngIf="config" class="form-group hide-arrow">
  <label *ngIf="config?.label" class="w-100 d-flex">
    <span [ngClass]="{ disabled: disabled }" class="text-truncate" title="{{ config.label }}">
      {{ config.label }}
    </span>
    <span *ngIf="isRequired && config.showRequiredMarker && !disabled" class="required-field"></span>
  </label>

  <div class="number-input-group">
    <input
      type="number"
      class="form-control"
      autocomplete="off"
      [id]="config.label"
      [placeholder]="config.placeholder || ''"
      [class.border--danger]="!disabled && hasError && control.touched"
      [(ngModel)]="value"
      [disabled]="disabled || false"
      [min]="config.allowNegative ? null : 0"
      [sliqpayNumeric]="{ allowNegative: config.allowNegative, allowDecimal: config.allowDecimal }"
      (keyup)="updateFormValue()"
      (blur)="onBlur()"
      (change)="onInputChange()"
    />
    <div class="number-input-group-buttons" *ngIf="!config.hideButtons">
      <button
        class="number-input-btn number-input-btn-increase mr-2"
        (click)="increment()"
        [disabled]="disabled"
      ></button>
      <button class="number-input-btn number-input-btn-decrease" (click)="decrement()" [disabled]="disabled"></button>
    </div>
  </div>

  <div class="invalid-feedback d-block" *ngIf="!disabled && hasError && control.touched">
    <div *ngIf="hasError.required || hasError.invalid">
      {{ config.errors?.required }}
    </div>
    <div *ngIf="hasError.min">{{ config.errors?.min }}</div>
    <div *ngIf="hasError.max">{{ config.errors?.max }}</div>
    <div *ngIf="hasError.nonZero">{{ config.errors?.nonZero }}</div>
  </div>
</div>
